import request from '@/utils/request'



export async function getOrgName(params) {
  const res = await request.get('/main/getConfig/name/org_name', params)
  if (res.data.code === 200) {
    return res.data.result;
  }
  return Promise.reject(new Error(res.data.message))
}

export async function getOrgInfo(params) {
  const res = await request.get('/main/getOrgInfo', params)
  if (res.data.code === 200) {
    return res.data.result;
  }
  return Promise.reject(new Error(res.data.message))
}

/**
 * 获取当前登录的用户信息、菜单、权限、角色
 */
 export async function getUserInfo() {
  const res = await request.get('/auth/getCurrentUserInfo')
  if (res.data.code === 200 && res.data.result) {
    return res.data.result
  }
  return Promise.reject(new Error(res.data.message))
}

/**
 * 获取评估模型
 */
 export async function getMenu() {
  const res = await request.get('/main/getMenu', {}, {isCancel: false})
  if (res.data.code === 200 && res.data.result) {
    return res.data.result
  }
  return Promise.reject(new Error(res.data.message))
}

/**
 * 获取评估员的评估数据
 */
export async function getAssessmentCount(data) {
  const res = await request.get('/main/getAssessmentCount', data)
  if (res.data.code === 200) {
    return res.data.result
  }
  return Promise.reject(new Error(res.data.message))
}



/**
 * 获取各个模型评估数量
 */
 export async function getModuleAssessmentCount(data) {
  const res = await request.get('/main/getData', data, {isCancel: false})
  if (res.data.code === 200) {
    return res.data.result
  }
  return Promise.reject(new Error(res.data.message))
}


/**
 * 检验身份证
 */
 export async function checkIdCardAndName(data) {
  const res = await request.post('/assessment/checkIdCardAndName', data)
  if (res.data.code === 200) {
    return res.data.result
  }
  return Promise.reject(new Error(res.data.message))
}

/**
 * 获取身份校验方式配置 （是否开启身份校验采用身份证还是门诊号）
 */
export async function getIdCardEnable(data) {
  const res = await request.get('/assessment/getIdCardEnable', data)
  if (res.data.code === 200) {
    return res.data.result
  }
  return Promise.reject(new Error(res.data.message))
}


/**
 * 修改密码
 */
export async function updatePassword(data) {
  const res = await request.put('/main/changePassword', data, {isCancel: false});
  if (res.data.code === 200) {
    return res.data.message;
    
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 根据类型修改当前用户的资质证明以及电子签名
 */
export async function setUpdatePic(data) {
  const res = await request.put('/main/updatePic', data);
 if (res.data.code === 200) {
   return res.data.message;
   
 }
 return Promise.reject(new Error(res.data.message));
}


/**
 * 获取上级医生
 */
export async function getAssessor(data) {
  const res = await request.get('/main/assessor', data);
 if (res.data.code === 200) {
   return res.data.result;
   
 }
 return Promise.reject(new Error(res.data.message));
}

/**
 * 订单合并
 * id 订单id
 * indexCatalogId 评估模型id
 * featuresStatus 评估模型子id
 */
export async function getMergeInfo(data) {
  const res = await request.get('/assessment/getMergeInfo', data);
  if (res.data.code === 200) {
    return res.data.result;
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 订单合并
 */
export async function mergeInto(data) {
  const res = await request.post('/assessment/mergeInto', data);
  if (res.data.code === 200) {
    return res.data.result;
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 消息通知
 */
export async function noticeList(data) {
  const res = await request.get('/notice/page', data, {isCancel: false});
  if (res.data.code === 200) {
    return res.data.result;
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 清除消息通知
 */
export async function updateRead(data) {
  const res = await request.get('/notice/updateRead', data, {isCancel: false});
  if (res.data.code === 200) {
    return res.data.result;
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 删除当个消息通知
 */
export async function updateReadById(data) {
  const res = await request.get('/notice/updateReadById', data);
  if (res.data.code === 200) {
    return res.data.result;
  }
  return Promise.reject(new Error(res.data.message));
}

