// // socket地址
// export const SOCKET_URL = process.env.SOCKET_URL

// // 接口地址
// export const API_BASE_URL = process.env.VUE_APP_API_BASE_URL

// // 上传 post
// export const UPLOAD = API_BASE_URL + '/file/upload' 

// 项目名称
export const PROJECT_NAME = process.env.VUE_APP_NAME
// 不显示侧栏的路由
export const HIDE_SIDEBARS = []

// 不显示页脚的路由
export const HIDE_FOOTERS = [
  '/system/dictionary',
  '/system/organization', 
  '/form/advanced',
  '/example/choose'
]

// 页签同路由不同参数可重复打开的路由
export const REPEATABLE_TABS = ['/system/user-info']

// 不需要登录的路由
export const WHITE_LIST = ['/login', '/forget', '/500', '/404', '/403', '/permissions', '/loading', '/no-user' ]

// 开启 KeepAlive 后仍然不需要缓存的路由地址
export const KEEP_ALIVE_EXCLUDES = []


export const ADMIN_LIST = ['/basicInfo', '/statisticAnalysis']

// 直接指定菜单数据
export const USER_MENUS = [
  {
    path: 'home',
    name: 'home',
    component: '/home',
    meta: { title: '首页', icon: 'el-icon--a-shouye1', sort: 1, hide: false, routeName: 'assess' }
  },
  {
    name: 'archivesAdmin',
    path: 'archivesAdmin',
    component: '/table/archivesAdmin',
    meta: { title: '档案管理', icon: 'el-icon--a-danganguanli1', name: 'archivesAdmin', sort: 2, hide: false, routeName: 'assess' }
  },
  // {
  //   name: 'basicInfo',
  //   path: 'basicInfo',
  //   component: '/basicInfo/assessor',
  //   meta: { title: '基础信息管理', icon: 'el-icon-user', name: 'basicInfo', sort: 4, hide: false },
  //   children: [
  //     {
  //       path: 'assessor',
  //       component: '/basicInfo/assessor',
  //       meta: { title: '评估员管理', hide: false }
  //     },
  //     {
  //       path: 'organization',
  //       component: '/basicInfo/organization',
  //       meta: { title: '科室管理', hide: false }
  //     },
      
  //   ]
  // },
  {
    name: 'statisticAnalysis',
    path: 'statisticAnalysis',
    component: '/statisticAnalysis/assessData',
    meta: { title: '统计分析', icon: 'el-icon--tongjifenxi', name: 'statisticAnalysis', sort: 5, hide: false, routeName: 'assess' },
    children: [
      {
        path: 'assessData',
        name: 'assessData',
        component: '/statisticAnalysis/assessData',
        meta: { title: '评估数据总览', hide: false, routeName: 'assess' }
      },
      {
        path: 'assessObject',
        name: 'assessObject',
        component: '/statisticAnalysis/assessObject',
        meta: { title: '评估对象总览', hide: false, routeName: 'assess' }
      },
      {
        path: 'assessDetail',
        name: 'assessDetail',
        component: '/statisticAnalysis/assessDetail',
        meta: { title: '评估明细报表', hide: false, routeName: 'assess' }
      }
    ]
  },
  {
    name: 'patientInfo',
    path: 'archives',
    // path: 'archives/:currentType/:userName/:patientId/:inpatientNum/:cardNo+/:elderlyId*',
    component: '/archives',
    meta: { title: '档案信息', hide: true, routeName: 'assess' },
  },
  {
    name: 'history',
    path: 'history',
    component: '/history',
    meta: { title: '历史评估', hide: true, routeName: 'assess' },
  },
  {
    name: 'assessmentResult',
    path: 'assessmentResult',
    component: '/assessment/assessmentResult',
    meta: { title: '评估结果', hide: true, routeName: 'assess' },
  },
  {
    name: 'assessment',
    path: 'assessment/:orderId/:elderlyId/:indexCatalogId',
    component: '/assessment',
    meta: { title: '正在评估', hide: true, routeName: 'assess' },
  },
  
  // {
  //   path: '/assessment',
  //   meta: { title: '评估管理', icon: 'iconfont action-pg' },
  //   children: [
  //     {
  //       path: '/screen',
  //       component: '/table',
  //       meta: { title: 'CGA筛查版', icon: 'el-icon-user', hide: false }
  //     },
  //     {
  //       path: '/tandard',
  //       component: '/table',
  //       meta: { title: 'CGA标准版', icon: 'el-icon-user', hide: false }
  //     },
  //     {
  //       path: '/nursing',
  //       component: '/table',
  //       meta: { title: '长护需求评估', icon: 'el-icon-user', hide: false }
  //     },
  //     {
  //       path: '/special',
  //       component: '/table',
  //       meta: { title: '专项评估', icon: 'el-icon-user', hide: false }
  //     }
  //   ]
  // }
]
// export const USER_MENUS = undefined;

// 首页名称, 为空则取第一个菜单的名称
export const HOME_TITLE = undefined

// 首页路径, 为空则取第一个菜单的地址
export const HOME_PATH = undefined

// 外层布局的路由地址
export const LAYOUT_PATH = '/'

// 刷新路由的路由地址
export const REDIRECT_PATH = '/redirect'

export const ID_CONFIG = {
  ID: {
    name: '身份证',
    param: 'cardNo'
  },
  OP: {
    name: '门诊号',
    param: 'patientId'
  }
} 

export const TEL = '400-835-0806'

// 开启多页签是否缓存组件
//export const TAB_KEEP_ALIVE = process.env.NODE_ENV !== 'development';
export const TAB_KEEP_ALIVE = true

// token 传递的 header 名称
export const TOKEN_HEADER_NAME = 'baoling-token'

// token 存储的名称
export const TOKEN_STORE_NAME = 'access_token'

// 个人信息 存储的名称
export const USERINFO_STORE_NAME = '__user_info'

export const BEFORE_ROUTER_INFO = '__before_router_info'

// 主题配置存储的名称
export const THEME_STORE_NAME = 'theme'

// i18n 缓存的名称
export const I18N_CACHE_NAME = 'i18n-lang'

// 高德地图 key
export const MAP_KEY = ''

// EleAdmin 授权码
export const LICENSE_CODE = 'dk9mcwJyetRWQlxWRiojIqJWdzJCLi4Wa3UjN1ojI0NWZshnI6ICZpJCLiwiIvlmd1lTU6Iibvl2cyVmdQfgISOuEjI0NW=='
