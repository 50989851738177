const menu = {
  path: '/',
  name: 'assess',
  component: () => import('@/views/index'),
  redirect: '/assess',
  meta: { title: '首页' },
  children: [
    {
      path: 'assess',
      name: 'Assess',
      redirect: '/assess/home',
      meta: { title: '老年综合征智慧防控平台', routeName: 'assess'},
      component: () => import('@/views/home'),
    },
    {
      path: '/know',
      name: 'Know',
      meta: { title: 'GS知识图谱', routeName: 'know' },
      component: () => import('@/views/know'),
    },
    {
      path: '/data',
      name: 'Data',
      meta: { title: 'GS云数据平台', routeName: 'data' },
      component: () => import('@/views/data'),
    },
    {
      path: '/know-list',
      name: 'KnowList',
      meta: { title: 'GS知识图谱', hide: true, routeName: 'know' },
      component: () => import('@/views/know/info'),
    }
  ]
}

export default menu