var timeout = null
var timer = null
var flag = null

const utils = {
   deepClone (obj) {
    let objClone = Array.isArray(obj) ? [] : {};
    if (obj && typeof obj === "object") {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
          //判断ojb子元素是否为对象，如果是，递归复制
          if (obj[key] && typeof obj[key] === "object") {
            objClone[key] = this.deepClone(obj[key]);
          } else {
            //如果不是，简单复制
            objClone[key] = obj[key];
          }
        }
      }
    }
    return objClone;
  },
  /**
   * 递归冻结
   */
  deepFreeze(obj) {
    console.log(obj)
    // 取回定义在obj上的属性名
    let propNames = Object.getOwnPropertyNames(obj)

    // 在冻结自身之前冻结属性
    propNames.forEach(function (name) {
      let prop = obj[name]

      // 如果prop是个对象，冻结它
      if (Object.prototype.toString.call(prop) == '[object Object]' && prop !== null) this.deepFreeze(prop)
    })
    // 冻结自身(no-op if already frozen)
    return Object.freeze(obj)
  },
  dateFormat(date, fmt = 'YYYY-mm-dd HH:MM') {
    let ret
    const opt = {
      "Y+": date.getFullYear().toString(),        // 年
      "m+": (date.getMonth() + 1).toString(),     // 月
      "d+": date.getDate().toString(),            // 日
      "H+": date.getHours().toString(),           // 时
      "M+": date.getMinutes().toString(),         // 分
      "S+": date.getSeconds().toString()          // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    }
    for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt)
      if (ret) {
        fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
      };
    };
    return fmt
  },
  vueDebounce(fnName, time) {
    let timeout = null
    return function() {
      const _arguments = arguments
      if (timeout) {
        clearTimeout(timeout)
      }
      timeout = setTimeout(() => {
        timeout = null
        this[fnName].apply(this, _arguments)
      }, time)
    }
  },
  /**
   * 防抖
   */
  debounce(func, wait = 500, immediate = false) {
    // 清除定时器
    if (timeout !== null) clearTimeout(timeout)
    // 立即执行，此类情况一般用不到
    if (immediate) {
      var callNow = !timeout
      timeout = setTimeout(function () {
        timeout = null
      }, wait)
      if (callNow) typeof func === 'function' && func()
    } else {
      // 设置定时器，当最后一次操作后，timeout不会再被清除，所以在延时wait毫秒后执行func回调方法
      timeout = setTimeout(function () {
        typeof func === 'function' && func()
      }, wait)
    }
  },
  /**
   * 节流
   */
  throttle(func, wait = 500, immediate = true) {
    if (immediate) {
      if (!flag) {
        flag = true
        // 如果是立即执行，则在wait毫秒内开始时执行
        typeof func === 'function' && func()
        timer = setTimeout(() => {
          flag = false
        }, wait)
      }
    } else {
      if (!flag) {
        flag = true
        // 如果是非立即执行，则在wait毫秒内的结束处执行
        timer = setTimeout(() => {
          flag = false
          typeof func === 'function' && func()
        }, wait)
      }

    }
  },
	filterPunctuation(str) {
		// 匹配中文和英文的标点符号
		let regex = /[，。、；？?|@{}！!,./\[\]\\]/g

		// 使用空字符串替换匹配到的标点符号
		let result = str.replace(regex, '');
		return result;
	}
}

export default utils