

import Mock from "mockjs"
import popularScience from './popularScience.json'
import guide from './guide.json'
import video from './video.json'
 
Mock.mock('/getArticleList', 'post', (options) => {
  let obj = JSON.parse(options.body)
  console.log(obj)
  if (obj.type == 'guide') {
    if (obj.queryVal) {
      return {code: 200, data: guide.filter(item => item.title.indexOf(obj.queryVal) > -1)}
    }
    return {code: 200, data: guide}
  } else if (obj.type == 'popularScience') {
    if (obj.queryVal) {
      return {code: 200, data: popularScience.filter(item => item.title.indexOf(obj.queryVal) > -1)}
    }
    return {code: 200, data: popularScience}
  } else {
    if (obj.queryVal) {
      return {code: 200, data: video.filter(item => item.title.indexOf(obj.queryVal) > -1)}
    }
    return {code: 200, data: video}
  }
})  

Mock.mock('/getArticleInfo', 'post', (options) => {
  let obj = JSON.parse(options.body)
  let arr = [...popularScience,...guide]
  let res = arr.find(item => obj.id == item.id)
  return {code: 200, data: res}
})

