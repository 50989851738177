/**
 * axios 实例
 */
import axios from 'axios'
import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import { MessageBox, Message } from 'element-ui'
import { TOKEN_HEADER_NAME } from '@/config/setting'
import { getToken, setToken } from './token-util'
import { WHITE_LIST } from '@/config/setting'
import qs from 'qs'
import { removeToken } from '@/utils/token-util';

const keyMap = new Map() // 保存唯一的key
const BASE_URL = process.env.BASE_URL; 
const service = axios.create({
  //请求多久延时
  timeout: 120000,
  // 延时后重新请求次数
  retry: 3,
  //延时后重新请求间隔
  retryInterval: 1000,
  isTips: true,
  // 设置请求头
  headers: {
    'Content-Type': 'application/json'
  }
})

/**
 * @description: 获取唯一标识
 * @param {*} config
 * @return {*}
 */
const generateRequestKey = (config) => {
  const { url, method, params, data } = config;
  return [url, method, qs.stringify(params), qs.stringify(data)].join('&');
}

/**
 * @description: 添加请求
 * @param {*} config
 * @return {*}
 */
const addPendingRequest = (config) => {
  const key = generateRequestKey(config);
  if(!keyMap.has(key)){
    config.cancelToken = new axios.CancelToken(cancel => {
      keyMap.set(key, cancel);
    })
  }
}

/**
 * @description: 删除重复请求
 * @param {*} config
 * @return {*}
 */
const removePendingRequest = (config) => {
  const key = generateRequestKey(config);
  if(keyMap.has(key)){
    const cancelToken = keyMap.get(key);
    cancelToken('已取消请求');
    keyMap.delete(key);
  }
}


/**
 * 添加请求拦截器
 */
service.interceptors.request.use(
  (config) => {
    removePendingRequest(config);
    addPendingRequest(config);
    const isCancel = !('isCancel' in config)
    if (isCancel) {
      config.cancelToken = new axios.CancelToken((cancel) => {
        store.commit('pushToken', { cancelToken: cancel })
      })
    }
    // 添加 token 到 header
    const token = getToken()
    if (config.hasOwnProperty('requestBaseUrl')) {
      config.baseURL = Vue.prototype.$config[config.requestBaseUrl]
    } else if (config.hasOwnProperty('requestDataUrl')) {
      config.baseURL = process.env.NODE_ENV === 'development' ? '/data' : Vue.prototype.$config[config.requestDataUrl]
    } else if (config.hasOwnProperty('requestLocal')) {
      config.baseURL = BASE_URL
    } else {
      config.baseURL = Vue.prototype.$config.VUE_APP_API_BASE_URL
    }

    if (config.headers['Content-Type'].indexOf('application/json') > -1) {
      config.data = JSON.stringify(config.data)
    } else {
      if (config.method === 'post' && config.headers['Content-Type'].indexOf('multipart/form-data') === -1) {
        let data = qs.parse(config.data)
        config.data = qs.stringify({ ...data })
      } else if (config.method === 'get') {
        config.params = { ...config.params }
      }
    }

    if (token && config.headers) {
      config.headers[TOKEN_HEADER_NAME] = token
    }
    return config
  },
  (error) => {
    if (error.message === 'LINK_CANCEL_AXIOS') {
        console.log('路由跳转取消请求' + error)
    } else {
        return Promise.reject(error)
    }
  }
)

/**
 * 添加响应拦截器
 */
service.interceptors.response.use(
  (res) => {
    removePendingRequest(res.config)
    const currentPath = router.currentRoute.path
    let isLogin = WHITE_LIST.indexOf(currentPath) !== -1
    // 登录过期处理
    if (res.config.responseType === 'blob') {
      return res
    } else if (res.data?.code === 401) {
      
      showMessageBox({
        title: '系统提示',
        msg: res.data?.message || '您的登录已过期，请重新登录！',
        isLink: !isLogin,
        link: BASE_URL + 'login' + (currentPath ? '?from=' + currentPath : ''),
      })
      // if (currentPath === '/') {
      //   // logout(true)
      //   removeToken()
      //   router.push({
      //     path: '/login'
      //   })
      // } else {
      //   if (currentPath === '/login') return
      //   MessageBox.alert(res.data?.message, '系统提示', {
      //     confirmButtonText: '确定',
      //     callback: (action) => {
      //       if (action === 'confirm') {
      //         removeToken()
      //         !isLogin && location.replace(BASE_URL + 'login' + (currentPath ? '?from=' + currentPath : ''));
      //       }
      //     },
      //     beforeClose: () => {
      //       MessageBox.close()
      //     }
      //   })
      // }
      // return Promise.reject(new Error(res.data.message))
    } else if (res.data?.code === 402) { // 登录过期处理
      showMessageBox({
        title: '系统提示',
        msg: '您的帐号已在其他设备登录，请确认帐号安全!',
        isLink: !isLogin,
        link: BASE_URL + 'login' + (currentPath ? '?from=' + currentPath : ''),
      })
    } else if (res.data?.code === 403) { // 账号过期
      showMessageBox({
        title: '系统提示',
        msg: '您的帐号已到期，想要继续使用，请联系管理员!',
        isLink: !isLogin,
        link: BASE_URL + 'login' + (currentPath ? '?from=' + currentPath : ''),
      })
    } else if (res.data?.code !== 200) {
      res.config.isTips && Message({
        message: res.data.message,
        type: 'error',
        duration: 3 * 1000
      })
    }
    if (router.history.current.name === '500') router.push({ name: 'archivesAdmin'})
    
    // token 自动续期
    const token = res.headers[TOKEN_HEADER_NAME.toLowerCase()]
    if (token) {
      setToken(token)
    }
    return res
  },
  (error) => {
    if (error?.code === 'ERR_NETWORK') {
      console.log(error)
      router.push({ path: '500' })
    }
    console.log(error)
    return Promise.reject(error)
  }
)


function showMessageBox ({ title, msg, isLink, link }) {
  MessageBox.alert(msg, title, {
    confirmButtonText: '确定',
    callback: (action) => {
      if (action === 'confirm') {
        removeToken()          
        isLink && location.replace(link);
      }
    },
    beforeClose: () => {
      MessageBox.close()
    }
  })
}

let http = {
  post(url, data, options) {
    return new Promise((resolve, reject) => {
      // service.post(url, params, options)
      service({
        method: 'post',
        url,
        data,
        ...options
      })
        .then(response => {
          resolve(response)
        }, err => {
          reject(err)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  get(url, params, options) {
    return new Promise((resolve, reject) => {
      // service.get(url, { params })
      service({
        method: 'get',
        url,
        params,
        ...options
      })
        .then(response => {
          resolve(response)
        }, err => {
          reject(err)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  put(url, data, options) {
    return new Promise((resolve, reject) => {
      // service.put(url, params)
      service({
        method: 'put',
        url,
        data,
        ...options
      })
        .then(response => {
          resolve(response)
        }, err => {
          reject(err)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  delete(url, params, options) {
    return new Promise((resolve, reject) => {
      // service.delete(url, { params })
      service({
        method: 'delete',
        url,
        params,
        ...options
      })
        .then(response => {
          resolve(response)
        }, err => {
          reject(err)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export default http

