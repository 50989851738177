// import { menuToRoutes, eachTreeData } from 'ele-admin';
// import { HOME_PATH, LAYOUT_PATH, REDIRECT_PATH } from '@/config/setting';
// import EleLayout from '@/layout/index.vue';
// import RedirectLayout from '@/components/RedirectLayout';
// import { iframeMixins } from '@/mixins/iframe-mixin';
import { REDIRECT_PATH } from '@/config/setting';

/**
 * 静态路由
 */
export const routes = [
  {
    path: '/login',
    component: () => import('@/views/login'),
    meta: { title: '登录' }
  },
  // {
  //   path: '/assessmentResult',
  //   name: 'assessmentResult',
  //   component: () => import('@/views/assessment/assessmentResult'),
  //   meta: { title: '登录' }
  // },
  
  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import('@/views/home'),
  //   meta: { title: '首页', icon: 'iconfont actionhome' }
  // },
  // {
  //   path: '/loading',
  //   component: () => import('@/views/login/loading'),
  //   meta: { title: '加载' }
  // },
  // {
  //   name: 'archives',
  //   path: '/archives/:type?',
  //   component: () => import('@/views/archives'),
  //   meta: { title: '档案信息' }
  // },
  // {
  //   path: '/forget',
  //   component: () => import('@/views/forget'),
  //   meta: { title: '忘记密码' }
  // },
  // 404
  {
    path: REDIRECT_PATH + '/:path(.*)',
    component: () => import('@/views/index')
  },
  {
    path: '*',
    component: () => import('@/views/exception/404')
  },
  {
    path: '/500',
    name: '500',
    component: () => import('@/views/exception/500')
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/exception/403')
  },
  {
    path: '/no-user',
    name: 'noUser',
    component: () => import('@/views/exception/no-user')
  },
  
];

// /**
//  * 添加动态路由
//  * @param menus 菜单数据
//  * @param homePath 菜单数据的第一个页面地址
//  */
// export function getMenuRoutes(menus, homePath) {
//   const routes = [
//     // 用于刷新的路由
//     {
//       path: REDIRECT_PATH + '/:path(.*)',
//       component: RedirectLayout,
//       meta: { hideFooter: true }
//     }
//   ];
//   // 路由铺平处理
//   eachTreeData(
//     menuToRoutes(menus, getComponent, [], undefined, undefined, iframeMixins),
//     (route) => {
//       routes.push({ ...route, children: undefined });
//     }
//   );
//   return {
//     path: LAYOUT_PATH,
//     component: EleLayout,
//     redirect: HOME_PATH ?? homePath,
//     children: routes
//   };
// }

/**
 * 获取路由组件
 * @param component 组件名称
 */
export function getComponent(component) {
  if (component) {
    return () => import('@/views/home' + component);
  }
}
