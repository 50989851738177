// import { formatMenus, toTreeData, formatTreeData } from 'ele-admin'
import { USER_MENUS } from '@/config/setting'
import { getUserInfo, getMenu, getIdCardEnable, getModuleAssessmentCount } from '@/api/layout'
import { USERINFO_STORE_NAME, ID_CONFIG, ADMIN_LIST } from '@/config/setting'
import { ASSESSMENT_STATUS_DATA, ASSESSMENT_STATUS_AUDIT_DATA } from '@/config/static-data'
import menu from '@/router/menu';
import { getComponent } from '@/router/routes';
import utils from '@/utils/utils'

export default {
  namespaced: true,
  state: {
    // 当前登录用户信息
    info: localStorage.getItem(USERINFO_STORE_NAME) ? JSON.parse(localStorage.getItem(USERINFO_STORE_NAME)) : null,
    // 当前登录用户的菜单
    menus: null,
    // 当前登录用户的权限
    authorities: [],
    // 当前登录用户的角色
    roles: [],
    // 评估模型
    assessmentModules: {},
    isID: false, // 是否开启身份证  默认不开启
    idConfig: ID_CONFIG['OP']
  },
  mutations: {
    // 设置登录用户的信息
    setUserInfo(state, info) {
      localStorage.setItem(USERINFO_STORE_NAME, JSON.stringify({ ...state.info, ...info}))
      state.info = { ...state.info, ...info, orgName: '葆龄科技'}
    },
    // 设置登录用户的菜单
    setMenus(state, menus) {
      state.menus = menus
    },
    // 设置登录用户的权限
    setAuthorities(state, authorities) {
      state.authorities = authorities
    },
    // 设置登录用户的角色
    setRoles(state, roles) {
      state.roles = roles
    },
    // 设置评估模型
    setAssessmentModules (state, assessmentModules) {
      state.assessmentModules = assessmentModules
    }, 
    // 设置身份校验方式配置
    // setIdEnable (state, val) {
    //   let key = val == 1 ? 'ID' : 'OP'
    //   state.idConfig = ID_CONFIG[key]
    //   state.isID = val == 1 ? true : false
      
    // },
  },
  actions: {
    /**
     * 请求用户信息、角色、评估数量
     */ 
    async fetchUserInfo ({ commit }, audit) {
      const userInfo = await getUserInfo().catch(() => undefined)
      let result = { ...userInfo, audit }
      if (!result) {
        return {}
      }
      // 用户信息
      commit('setUserInfo', result)
     },
    async fetchCount ({ state, commit }) {
      const moduleAssessmentCount = await getModuleAssessmentCount().catch(() => undefined)
      if (moduleAssessmentCount && state.assessmentModules && Object.keys(state.assessmentModules).length) {
        let arr = [ ...state.assessmentModules ]
        arr = arr.map(item => {
          let obj = moduleAssessmentCount.find(moduleAssessmentCountItem => moduleAssessmentCountItem.index_catalog_id === item.id)
          return { ...item, total: obj ? obj.total * 1 : 0}
        })
        commit('setAssessmentModules', arr)
      }
      let defaultData = {
        haveIn: 0,
        haveAssess: 0,
        waitAudit: 0,
        wait: 0
      }
      let result = { ...defaultData }
      if (!result) {
        return {}
      }
      // 用户信息
      commit('setUserInfo', result)
     },
    /**
     * 请求菜单
     */
    async fetchMenus({ commit, dispatch, getters }) {
      const result = await getMenu().catch(() => undefined)
      dispatch('fetchCount')
      commit('setAssessmentModules', result)
      if (!result) throw new Error('获取菜单评估模型错误！！！') 
      let arr = JSON.parse(JSON.stringify(result))
      let moduls = [] 
      arr.forEach((item, idx) => {
        if (item.hasOwnProperty('list') && item.list && item.list.length) {
          let copyItem = []
          let list = item.list.filter(subItem => !('is_use' in subItem))
          if (!list.lenght) {
            copyItem = { ...item, list }
          } else {
            Object.keys(item).forEach(key => {
              if (key !== 'list') {
                copyItem[key] = item[key]
              }
            })
          }

          moduls.push(copyItem)
        } else {
          moduls.push(item)
        }
      })

      let children = moduls && moduls.map(item => ({
        path: `${item.path}`,
        name: `${item.path}`,
        component: '/table/assessmentTable',
        keepAlive: true,
        meta: { title: item.indexName, icon: item.icon, hide: false, name: item.list && Array.isArray(item.list) &&item.list.length ? 'assessmentAdminItem' : 'assessmentAdmin', indexCatalogId: item.id, routeName: 'assess' },
        children: item.list && Array.isArray(item.list) &&item.list.length ? item.list.map((subItem, subIndex) => ({
          path: `${subItem.path}${subIndex}`,
          name: `${subItem.path}${subIndex}`,
          component: '/table/assessmentTable',
          keepAlive: true,
          meta: { title: subItem.index_name, icon: subItem.icon, hide: true, name: 'assessmentAdminItem', indexCatalogId: subItem.id, parentId: subItem.parent_id, isAssessment: true, routeName: 'assess' }
        })) : []
      }))
      let obj = result ? {
        name: 'assessmentAdmin',
        path: 'assessment',
        keepAlive: true,
        meta: { title: '评估管理', icon: 'el-icon--a-pingguguanli1', isAssessment: true, sort: 3, routeName: 'assess' },
        children
      } : {}

      let menusArr = [ ...USER_MENUS, obj ].map(item => ({ ...item, meta: { ...item.meta, sort: item.meta.sort ? item.meta.sort : 999 } }))
      menusArr.sort((a, b) => a.meta.sort - b.meta.sort)
      !getters.isAdmin && __handleMenuCallback (menusArr, ADMIN_LIST)

      function __handleMenuCallback (arr, list) {
        for (let i = 0; i < arr.length; i++) {
          const item = arr[i];
          if (list.indexOf(item.path) > -1) {
            arr.splice(i, 1)
            i--
          } else if (item.children && item.children.length) {
            __handleMenuCallback(item.children, list)
          }
        }
      }
      // 用户菜单, 过滤掉按钮类型并转为 children 形式
      // let { menus, homePath } = formatMenus(menusArr)
      let pagesMenu = utils.deepClone(menu)
      pagesMenu.children[0].children = menusArr

      let routeMenu = utils.deepClone(pagesMenu)

      let menus = __menuCallback(menusArr, routeMenu.children[0].path)
              
      function __menuCallback(data, key) {
        let arr = []
        for (let i = 0; i < data.length; i++) {
          let item = data[i];

          if (item.component) {
            item.component = getComponent(item.component)
          }
          if (item.children && item.children.length) {
            // item.redirect = `/${key}/${item.path}/${item.children[0].path}`
            // item.children = __menuCallback(item.children, item.path)
            arr.push({ ...item, children: [] })
            arr = [ ...arr, ...__menuCallback(item.children)]
          } else {
            arr.push(item)
          }
        }
        return arr
      }
      routeMenu.children[0].children = menus
      routeMenu.children[0].redirect = `/${routeMenu.children[0].path}/${menus[0].path}`
      commit('setMenus', pagesMenu)

      return routeMenu 
    },
    /**
     * 身份校验方式配置
     */
    //  async fetchIdCardEnable({ commit }) {
    //   const result = await getIdCardEnable().catch(() => undefined)
    //   commit('setIdEnable', result)
    //  },
    /**
     * 更新用户信息
     */
    setInfo({ commit }, value) {
      commit('setUserInfo', value)
    },
    /**
     * 更新菜单的badge
     */
    setMenuBadge({ commit, state }, { path, value, color }) {
      const menus = formatTreeData(state.menus, (m) => {
        if (path === m.path) {
          return Object.assign({}, m, {
            meta: Object.assign({}, m.meta, {
              badge: value,
              badgeColor: color
            })
          })
        }
        return m
      })
      commit('setMenus', menus)
    }
  },
  getters: {
    userInfo: (state) => {
      return state.info
    },
    isAdmin: (state, getter) => {
      return getter.userInfo.currentType == 1
    },
    assessmentModules: (state) => {
      let arr = state.assessmentModules.filter(item => !('is_use' in item))
      let newArr = []
      arr.forEach(item => {
        if ('list' in item) {
          let list = []
          item.list.forEach(subItem => {
            !('is_use' in subItem) && list.push(subItem)
          })
          newArr.push({ ...item, list})
        } else {
          newArr.push(item)
        }
      })
      return newArr
    },
    cascaderModeloptions: (state, getters) => {
      let arr = [{ label: '全部', value: ''}]
      getters.assessmentModules.forEach(item => {
        let obj = { label: item.indexName, value: item.id }
        if ('list' in item) {
          obj.children = []
          item.list.forEach(subItem => {
            obj.children.push({ label: subItem.indexName, value: subItem.id })
          })
        }
        arr.push(obj)
      })
      return arr
    },
    modeloptions: (state, getters) => {
      let arr = [{ indexCatalogName: '全部', indexCatalogId: '', featuresStatus: '', }]
      getters.assessmentModules.forEach(item => {
        if ('list' in item) {
          item.list.forEach(subItem => {
            arr.push({ indexCatalogName: subItem.indexName, indexCatalogId: subItem.id, featuresStatus: subItem.parent_id, })
          })
        } else {
          arr.push({ indexCatalogName: item.indexName, indexCatalogId: item.id, featuresStatus: '', })
        }
      })
      return arr
    },
    assessmentStatusOptions: (state, getter) => {
      return getter.userInfo.audit ? [ ...ASSESSMENT_STATUS_AUDIT_DATA ] : [ ...ASSESSMENT_STATUS_DATA ]
    }
  }
}
