/**
 * vuex状态管理
 */
import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import user from './modules/user';
import theme from './modules/theme';

import { getBeforeRouterInfo } from '@/utils/before-router-info-util'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    beforeRouterInfo: getBeforeRouterInfo(),
    cancelTokenArr: []
  },
  mutations: {
    pushToken: (state, src) => {
      state.cancelTokenArr.push(src.cancelToken)
    },
    clearToken: ({cancelTokenArr}) => {
      cancelTokenArr.forEach(callback => {
        callback('LINK_CANCEL_AXIOS')
      })
      cancelTokenArr = []
    }
  },
  actions: {},
  modules: {
    user,
    theme
  },
  getters
});
