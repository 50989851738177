export default {
  // 时间转换 time秒
  timeFormat(time, isZH = false){
    let key = isZH ? 'zh' : 'en' 
    let options = {
      en: {
        SS: 's',
        MM: 'm',
        HH: 'h',
        DD: 'd',
      },
      zh: {
        SS: '秒',
        MM: '分',
        HH: '小时',
        DD: '天',
      }
    }
    let obj = options[key]
    let m = Math.floor(time / 60)
    let h = Math.floor(time / 60 / 60)
    let d = Math.floor(time / 60 / 60 / 24)
    let str = ''
    if (d >= 1) {
      let num = time - d * 60 * 60 * 24

      let hhFormula = Math.floor(num / 60 / 60)
      let HH = hhFormula >= 1 ? hhFormula : 0

      let mmFormula =  Math.floor((num - (HH * 60 * 60)) / 60)
      let MM = mmFormula >= 1 ? mmFormula : 0

      let SS = num - hhFormula * 60 * 60 - MM * 60
      str = `${d}${obj.DD} ${HH}${obj.HH} ${MM}${obj.MM} ${SS}${obj.SS}`
    } else if (h >= 1) {
      let num = time - h * 60 * 60

      let mmFormula =  Math.floor(num / 60)
      let MM = mmFormula >= 1 ? mmFormula : 0

      let SS = num - MM * 60
      str = `${h}${obj.HH} ${MM}${obj.MM} ${SS}${obj.SS}`
    } else if (m >= 1) {
      let MM = m * 60
      str = `${m}${obj.MM} ${time - MM}${obj.SS}`
    } else {
      str = `${time}${obj.SS}`
    }

    if (isZH) return str.replace(/\s+/g, '')
    return str
  },
  assessmentStatusTxt (status) {
    if (status == '0') {
      return '未分配'
    }
    if (status == '1') {
      return '待评估'
    }
    if (status == '2') {
      return '评估中'
    }
    if (status == '3') {
      return '待审核'
    }
    if (status == '4') {
      return '待审核'
    }
    if (status == '5') {
      return '已评估'
    }
  }
}