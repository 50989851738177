
import { BEFORE_ROUTER_INFO } from '@/config/setting';
import router from '@/router'

const defaultData = {
  name: '',
  query: {},
  params: {},
}
/**
 * 获取缓存  跳转个人档案的之前的路由信息
 */
export function getBeforeRouterInfo() {
  
  const str = sessionStorage.getItem(BEFORE_ROUTER_INFO)
  let beforeRouterInfo = str ? JSON.parse(str) : defaultData
  return beforeRouterInfo
}

/**
 * 缓存
 */
export function setBeforeRouterInfo(obj) {
  removeRouterInfo();
  console.log(obj)
  if (obj && Object.prototype.toString.call(obj) === '[object Object]' && Object.keys(obj).length) return sessionStorage.setItem(BEFORE_ROUTER_INFO, JSON.stringify(obj));
  else {
    sessionStorage.setItem(BEFORE_ROUTER_INFO, JSON.stringify({ name: router.currentRoute.name, query: router.currentRoute.query, params: router.currentRoute.params }))
  }
  // setBeforeRouterInfo({ name: router.currentRoute.name, query: router.currentRoute.query, params: router.currentRoute.params })
}

/**
 * 移除 跳转个人档案的之前的路由信息
 */
export function removeRouterInfo() {
  sessionStorage.removeItem(BEFORE_ROUTER_INFO);
}
