
import '@babel/polyfill'

import Vue from 'vue'
import App from './App.vue'
import router from './router';
import axios from 'axios'
import store from './store';
import utils from '@/utils/utils';
import request from '@/utils/request';
import vueEsign from 'vue-esign'
import filters from '@/utils/fulters'
import global from '@/utils/global'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/index.scss';
import permission from './utils/permission';
import loading from '@/components/CommLoading/index.js'
import elementPatch from '@/utils/element-patch'
import VueClipboard from 'vue-clipboard2';

import '@/mock/index'

Vue.use(ElementUI)
import directive from '@/directive'

Vue.use(directive)

Vue.prototype.$http = request;
// Vue.prototype.$loading = loading
Vue.prototype.$utils = utils
Vue.prototype.$global = global

Vue.prototype.$bus = new Vue()

Vue.config.productionTip = false;

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.config.productionTip = false

const { BASE_URL, NODE_ENV } = process.env
Vue.use(vueEsign).use(permission).use(loading).use(elementPatch).use(VueClipboard);

async function getConfig() {
  return axios.get(BASE_URL + `json/config.json`).then((res) => {
    let data = res.data[NODE_ENV]
    Vue.prototype.$config = data
  })
}

async function createApp () {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
}


(async function() {
  await getConfig()
  await createApp()
})()