/**
 * 路由配置
 */
import Vue from 'vue';
import NProgress from 'nprogress';
import VueRouter from 'vue-router';
import { PROJECT_NAME, WHITE_LIST, REDIRECT_PATH } from '@/config/setting';
import store from '@/store';
import { getToken } from '@/utils/token-util';
import { routes, getComponent } from './routes';
import menu from './menu';

Vue.use(VueRouter);

// 捕获push replace中的错误
// 当然在replace中的错误也是可以相同的进行捕获
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes,
  mode: 'history',
  // base: '/pc/',
  scrollBehavior() {
    return { y: 0 };
  }
});

/**
 * 路由守卫
 */
router.beforeEach((to, from, next) => {
  store.commit('clearToken')
  if (!from.path.includes(REDIRECT_PATH)) {
    NProgress.start();
  }
  updateTitle(to);
  if (WHITE_LIST.includes(to.path)) {
    next();

  } else {
    // 判断是否登录
    if (getToken()) {
      // 还未注册动态路由则先获取
      if (!store.state.user.menus) {
        store
          .dispatch('user/fetchMenus')
          .then((menus) => {
            console.log(menus)
            if (menus) {
              router.addRoute(menus);
            } else {
              router.addRoute(menu)
            }
            next({ ...to, replace: true });
          })
          .catch((e) => {
            console.error(e);
            next();
          });
        // store.dispatch('user/fetchIdCardEnable')
      } else {
        next();
      }
    } else if (WHITE_LIST.includes(to.path)) {
      next();
    } else {
      next({
        path: '/login',
        query: to.path === '/' ? {} : { from: to.path }
      });
    }
  }
});

router.afterEach((to) => {
  if (!to.path.includes(REDIRECT_PATH)) {
    setTimeout(() => {
      NProgress.done(true);
    }, 300);
  }
});

/**
 * 更新浏览器标题
 * @param route 路由信息
 */
function updateTitle(route) {
  if (route && !route.path?.includes(REDIRECT_PATH)) {
    const names = [];
    if (route.meta?.title) {
      names.push(route.meta.title);
    }
    if (PROJECT_NAME) {
      names.push(PROJECT_NAME);
    }
    document.title = names.join(' - ');
  }
}

export default router;
