const HOME_ASSESSENT_LIST = [
  {
    id: 'de997a428dd611eb98bb00163e10201d',
    name: 'CGA筛查版',
    bgImages: `url(${require('@/assets/scale-bg.png')})`,
    styles: {
      backgroundImage: 'linear-gradient(135deg, #5365B1 0%, #3E50A6 100%)',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat' 
    }
  },
  {
    id: 'de997a6b8dd611eb98bb00163e10201d',
    name: 'CGA标准版',
    bgImages: `url(${require('@/assets/scale-bg2.png')})`,
    styles: {
      backgroundImage: 'linear-gradient(135deg, #2BCAD0 0%, #04B8DB 100%)',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat' 
    }
  },
  {
    id: 'de997a728dd611eb98bb00163e10201d',
    name: '长护需求评估',
    bgImages: `url(${require('@/assets/scale-bg3.png')})`,
    styles: {
      backgroundImage: 'linear-gradient(134deg, #FEB393 0%, #FD9683 100%)',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat' 
    }
  },
  {
    id: 'de997a778dd611eb98bb00163e10201d',
    name: '专项评估',
    bgImages: `url(${require('@/assets/scale-bg4.png')})`,
    styles: {
      backgroundImage: 'linear-gradient(135deg, #5FABFF 0%, #6E90FE 100%)',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat' 
    }
  },
]

const CREATE_ARCHIVES_OPTIONS = [
  {
    label: '居民身份证', 
    value: 'cardNo', 
    placeholder: '请输入证件号',
    rule: value => {
      let flag = /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(value)
      if (value.length !== 18) {
        return {
          bool: true,
          message: '您输入的身份证长度错误',
        }
      } else if (!flag) {
        return {
          bool: true,
          message: '您输入的身份证格式错误',
        }
      }
      return false
    }
  },
  {
    label: '门诊号', 
    value: 'patientId',
    placeholder: '请输入门诊号'
  },
  {
    label: '住院号', 
    value: 'inpatientNum',
    placeholder: '请输入住院号'
  },
  // {
  //   label: '联系电话', 
  //   value: 'mobile', 
  //   placeholder: '请输入联系电话',
  //   rule: value => {
  //     let flag = /^[\d+-]+$/.test(value) 
  //     if (flag && value.length >=6) {
  //       return { bool: false}
  //     }
  //     return {
  //       bool: true,
  //       message: '你输入的联系电话格式有误'
  //     }
  //   }
  // }
]

const ASSESSMENT_STATUS_AUDIT_DATA = [
  {
    key: 'all',
    value: '',
    label: '全部',
  },
  {
    key: 'wait',
    value: '1',
    label: '待评估',
    color: '#ED4242',
    bgColor: '#FFDFDF'
  },
  {
    key: 'haveIn',
    value: '2',
    label: '评估中',
    color: '#1F85DE',
    bgColor: '#DFF0FF'
  },
  {
    key: 'waitAudit',
    value: '3',
    label: '待审核',
    color: '#26B44E',
    bgColor: '#DFFFE8'
  },
  {
    key: 'haveAssess',
    value: '5',
    label: '已评估',
    color: '#26B44E',
    bgColor: '#DFFFE8'
  }
]

const ASSESSMENT_STATUS_DATA = ASSESSMENT_STATUS_AUDIT_DATA.filter(item => item.value != 3)

const DATE_OPTION = {
  shortcuts: [{
    text: '最近一周',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      picker.$emit('pick', [start, end]);
    }
  }, {
    text: '最近一个月',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      picker.$emit('pick', [start, end]);
    }
  }, {
    text: '最近三个月',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      picker.$emit('pick', [start, end]);
    }
  }]
}


const SEX_OPTIONS = [
  {
    value: '',
    label: '全部'
  },
  {
    value: '1',
    label: '男'
  },
  {
    value: '2',
    label: '女'
  }
]

const num = 100
let AGE_OPTIONS = [{ value: '50_' + num * 100, label: '全部' }]
for (let i = 49; i < num; i++) {
  if (i < num - 1) {
    let currentNum = i + 1
    let obj = {
      value: currentNum,
      label: currentNum + '岁',
      children: []
    }
    for (let k = 0; k < num - i; k++) {
      let subNum = k + currentNum
      obj.children.push({
        value: `${currentNum}_${subNum}`,
        label: subNum + '岁'
      })
    }
    AGE_OPTIONS.push(obj)
  }
}
AGE_OPTIONS.push({
  value: num + '_' + num * 100,
  label: num + '岁以上',
})

export { HOME_ASSESSENT_LIST, ASSESSMENT_STATUS_DATA, ASSESSMENT_STATUS_AUDIT_DATA, DATE_OPTION, SEX_OPTIONS, CREATE_ARCHIVES_OPTIONS, AGE_OPTIONS }